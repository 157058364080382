<template>
  <tr>
    <td>{{ wagon.id }}</td>
    <td>
      <v-badge
        :content="wagon.graffitiesCount"
        :value="wagon.graffitiesCount"
        :color="wagon.graffitiesCount > 0 ? 'red' : 'green'"
        overlap>
        <v-icon large>
          mdi-train
        </v-icon>
      </v-badge>
    </td>
    <td>{{ wagonType[wagon.type] }}</td>
    <td>
      <v-icon v-if="wagon.analyzed" color="green">mdi-checkbox-marked-circle</v-icon>
      <v-icon v-if="!wagon.analyzed" color="red">mdi-cancel</v-icon>
    </td>
    <td>
      <template v-if="wagon.graffitiesCount > 0">
        <v-btn icon @click="showModal('original', wagon.extra)">
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-btn icon @click="showModal('catched', wagon.extra)">
          <v-icon>mdi-camera</v-icon>
        </v-btn>
        <v-btn icon @click="showModal('graffiti', wagon.extra)">
          <v-icon>mdi-camera</v-icon>
        </v-btn>
      </template>
    </td>
  </tr>
</template>

<script>

export default {
  name: 'WagonRow',
  props: {
    wagon: {
      type: Object,
      required: true,
    },
    trainUid: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      wagonType: {
        TRAIN_BEGIN: 'Start of the train',
        TRAIN_WAGON: 'Wagon',
        TRAIN_END: 'End of the train',
      },
      graffitiParams: {
        trainUID: null,
        wagonUid: null,
        imageType: null,
      },
    };
  },

  methods: {
    showModal(modalType, wagonExtra) {
      const parsedExtra = JSON.parse(wagonExtra);
      this.graffitiParams = {
        trainUID: this.$route.params.trainUid,
        wagonId: parsedExtra.wagonId,
        imageType: modalType,
      };
      this.$emit('openModal', this.graffitiParams);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
